var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "background-color": _vm.style.softColor,
      "padding": "14px 30px",
      "align-items": "center",
      "gap": "10px"
    }
  }, [_c('c-box', {
    attrs: {
      "height": "23px",
      "width": "23px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-warn.svg'),
      "height": "23px",
      "width": "23px",
      "fill": _vm.style.colorHex
    }
  })], 1), _c('c-text', {
    attrs: {
      "color": _vm.style.color,
      "font-size": "14px"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }