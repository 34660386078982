var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "padding": "0px 12px 4px 0px",
      "color": _vm.isInvalidInput || _vm.isInvalidSelect ? '#D32737' : _vm.inputValue && _vm.selectValue ? '#008C81' : '#555'
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-flex', {
    attrs: {
      "template-columns": ['repeat(2, 1fr)']
    }
  }, [_c('FormInput', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input': {
          borderTopRightRadius: '0px !important',
          borderBottomRightRadius: '0px !important'
        }
      },
      expression: "{\n        'input': {\n          borderTopRightRadius: '0px !important',\n          borderBottomRightRadius: '0px !important',\n        }\n      }"
    }],
    attrs: {
      "value": _vm.inputValue,
      "type": "text",
      "label": "",
      "placeholder": _vm.inputPlaceholder,
      "is-required": "",
      "is-invalid": _vm.isInvalidInput,
      "invalid-text": _vm.invalidInputText,
      "is-without-label": "",
      "flex-grow": "1",
      "is-disabled": _vm.isDisabled
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change:input', $event);
      },
      "blur": function blur($event) {
        return _vm.$emit('blur:input');
      }
    }
  }), _c('c-box', {
    attrs: {
      "flex-basis": "0",
      "flex-shrink": "0",
      "min-width": "fit-content",
      "transition-duration": "300ms"
    }
  }, [_c('FormSelect', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'select': {
          borderTopLeftRadius: '0px !important',
          borderBottomLeftRadius: '0px !important',
          backgroundColor: '#F2F2F2'
        }
      },
      expression: "{\n          'select': {\n            borderTopLeftRadius: '0px !important',\n            borderBottomLeftRadius: '0px !important',\n            backgroundColor: '#F2F2F2',\n          }\n        }"
    }],
    attrs: {
      "value": _vm.selectValue,
      "label": "",
      "placeholder": _vm.selectPlaceholder,
      "options": _vm.selectOptions,
      "is-required": "",
      "is-disabled": _vm.isDisabled,
      "is-invalid": _vm.isInvalidSelect,
      "invalid-text": _vm.invalidSelectText,
      "is-without-label": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change:select', $event);
      },
      "blur": function blur($event) {
        return _vm.$emit('blur:select');
      }
    }
  }), _vm.selectValue === 'Lainnya' ? _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "",
      "placeholder": "Masukkan lainnya",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Maksimal 20 karakter",
      "is-without-label": "",
      "is-invalid": _vm.isInvalidSelectOther,
      "invalid-text": _vm.invalidSelectOtherText
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change:select-other', $event);
      },
      "blur": function blur($event) {
        return _vm.$emit('blur:select-other');
      }
    },
    model: {
      value: _vm.selectOtherValue,
      callback: function callback($$v) {
        _vm.selectOtherValue = $$v;
      },
      expression: "selectOtherValue"
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }