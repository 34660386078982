<template>
  <c-flex
    flex-direction="column"
    align-items="center"
    width="100%"
    min-width="75px"
    min-height="10px"
  >
    <c-image
      :src="image || require('@/assets/images/image-gray.png')"
      object-fit="cover"
      size="72px"
      border-radius="8px"
    />
    <c-text
      padding-top="2px"
      font-size="14px"
      text-align="center"
    >
      {{ name }}
    </c-text>
    <c-text
      color="neutral.888888"
      font-size="14px"
      text-align="center"
    >
      {{ servingSize }} {{ servingSizeUnit }}
    </c-text>
  </c-flex>
</template>

<script>
import { CFlex, CImage, CText } from '@chakra-ui/vue'

export default {
  name: 'MealPortion',
  components: { CFlex, CText, CImage },
  props: {
    image: {
      type: String,
      default: '', // require('@/assets/images/image-gray.png')
    },
    name: {
      type: [String, Number],
      default: '-', // Nasi Putih
    },
    servingSize: {
      type: [String, Number],
      default: '-', // 200
    },
    servingSizeUnit: {
      type: [String, Number],
      default: '-', // gram
    },
  },
}
</script>
