<template>
  <BaseModal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    :with-button-close="false"
    size="700px"
  >
    <template #header>
      <c-box
        position="relative"
      >
        <c-flex
          width="100%"
          padding="16px"
          justify-content="center"
          align-items="center"
        >
          {{ title }}
        </c-flex>
        <c-button
          variant="ghost"
          position="absolute"
          right="12px"
          top="8px"
          width="40px"
          height="40px"
          @click="() => $emit('change:is-open', false)"
        >
          <c-icon
            name="close"
            size="20px"
          />
        </c-button>
      </c-box>
    </template>
    <template #body>
      <c-flex
        position="relative"
        flex-direction="column"
        justify-content="center"
        text-align="center"
      >
        <c-box
          margin-inline="24px"
          padding-bottom="8px"
        >
          <c-form-control
            width="100%"
            pos="relative"
          >
            <c-image
              :src="require('@/assets/ic-search.svg')"
              alt="icon"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              left="15px"
              height="24px"
              z-index="2"
            />
            <c-input
              id="search"
              v-model="temporaryFilterSearch"
              type="text"
              w="100%"
              height="48px"
              placeholder="Pencarian"
              border-radius="20px"
              padding-left="50px"
              position="relative"
              :font-size="['14px', '16px']"
              z-index="1"
            />
          </c-form-control>
        </c-box>
        <c-box
          v-if="!isLoadingDataFood"
          max-height="380px"
          height="100%"
          overflow-y="auto"
          padding-block="8px"
          padding-inline="24px"
        >
          <c-box
            v-if="options.length > 0"
          >
            <c-grid
              width="100%"
              template-columns="repeat(5, 1fr)"
              gap="8px"
            >
              <c-flex
                v-for="(food, i) in options"
                :key="i"
                position="relative"
                flex-direction="column"
                justify-content="flex-start"
                align-items="center"
                width="100%"
                padding="16px"
                border-radius="12px"
                :_hover="{ backgroundColor: temporarySelectedFood?.id === food?.id ? 'primary.400' : 'neutral.superLightGray' }"
                :background-color="temporarySelectedFood?.id === food?.id ? 'primary.400' : 'white'"
                cursor="pointer"
                @click="() => temporarySelectedFood = food"
              >
                <c-box>
                  <c-image
                    :src="food?.photoUrl"
                    width="72px"
                    height="72px"
                    object-fit="cover"
                    object-position="center"
                    border-radius="8px"
                    box-shadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
                    margin-bottom="8px"
                  />
                </c-box>
                <c-text
                  font-size="14px"
                  :color="temporarySelectedFood?.id === food?.id ? 'white' : 'unset'"
                >
                  {{ food?.foodName }}
                </c-text>
                <c-text
                  font-size="14px"
                  :color="temporarySelectedFood?.id === food?.id ? 'white' : 'neutral.888888'"
                >
                  {{ food?.servingSizePerPortion }} {{ food?.servingSizeUnitPerPortion }}
                </c-text>
              </c-flex>
            </c-grid>
            <c-flex
              justify-content="center"
              w="100%"
              margin-top="16px"
            >
              <c-flex justify-content="center">
                <c-button
                  variant="ghost"
                  variant-color="primary"
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  :is-disabled="filter.page <= 1"
                  @click.prevent="() => $emit('change:filter-page', filter.page - 1)"
                >
                  <c-icon
                    name="chevron-left"
                    size="24px"
                  />
                </c-button>

                <c-button
                  v-for="page_ in pages"
                  :key="page_"
                  :variant="page_ === filter.page ? 'outline' : 'ghost'"
                  variant-color="primary"
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  mx="4px"
                  @click="() => $emit('change:filter-page', page_)"
                >
                  {{ page_ }}
                </c-button>

                <c-button
                  variant="ghost"
                  variant-color="primary"
                  px="0"
                  py="4px"
                  w="32px"
                  h="32px"
                  :is-disabled="pages.length === filter.page"
                  @click.prevent="() => $emit('change:filter-page', filter.page + 1)"
                >
                  <c-icon
                    name="chevron-right"
                    size="24px"
                  />
                </c-button>
              </c-flex>
            </c-flex>
          </c-box>
          <c-flex
            v-else
            width="100%"
            min-height="100px"
            justify-content="center"
            align-items="center"
          >
            <c-text
              font-size="18px"
              font-weight="500"
              color="danger.400"
            >
              Data tidak ditemukan
            </c-text>
          </c-flex>
        </c-box>
        <c-box
          v-else
          text-align="center"
          margin-block="36px"
        >
          <c-spinner
            thickness="4px"
            speed="0.65s"
            empty-color="green.200"
            color="primary.400"
            size="xl"
          />
          <c-text
            color="primary.400"
            font-weight="500"
          >
            Memuat data...
          </c-text>
        </c-box>
      </c-flex>
    </template>
    <template #footer>
      <c-flex
        gap="8px"
        width="100%"
        align-items="center"
        justify-content="center"
        padding-top="8px"
        padding-left="16px"
        padding-right="16px"
        margin-bottom="30px"
      >
        <BaseButton
          flex-grow="1"
          rounded="1000px"
          width="100%"
          max-width="400px"
          :disabled="!temporarySelectedFood?.id"
          @click="() => $emit('change:selected-food', temporarySelectedFood)"
        >
          Konfirmasi
        </BaseButton>
      </c-flex>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/elements/base-modal.vue'
import BaseButton from '@/components/elements/base-button.vue'
import { CBox, CButton, CFlex, CFormControl, CGrid, CImage, CInput, CSpinner, CText, CIcon } from '@chakra-ui/vue'
import _ from 'lodash'

export default {
  name: 'ModalChooseMenu',
  components: { BaseButton, BaseModal, CText, CBox, CFlex, CButton, CFormControl, CInput, CGrid, CImage, CSpinner, CIcon },
  props: {
    title: {
      type: String,
      default: '-',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoadingDataFood: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedFoods: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Object,
      default: () => ({}),
    },
    pages: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'change:is-open',
    'change:selected-food',
    'change:filter-search',
    'change:filter-page',
  ],
  data() {
    return {
      temporaryFilterSearch: '',
      temporarySelectedFood: {},
    }
  },
  watch: {
    'temporaryFilterSearch': {
      handler: _.debounce(async function(newVal) {
        this.$emit('change:filter-search', newVal)
      }, 800),
      deep: true,
    },
  },
}
</script>
