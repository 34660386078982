import { render, staticRenderFns } from "./modal-choose-menu.vue?vue&type=template&id=1f91297e&"
import script from "./modal-choose-menu.vue?vue&type=script&lang=js&"
export * from "./modal-choose-menu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CText: require('@chakra-ui/vue').CText, CGrid: require('@chakra-ui/vue').CGrid, CSpinner: require('@chakra-ui/vue').CSpinner})
