<template>
  <c-flex
    :background-color="style.softColor"
    padding="14px 30px"
    align-items="center"
    gap="10px"
  >
    <c-box
      height="23px"
      width="23px"
    >
      <inline-svg
        :src="require('@/assets/icons/icon-circle-warn.svg')"
        height="23px"
        width="23px"
        :fill="style.colorHex"
      />
    </c-box>
    <c-text
      :color="style.color"
      font-size="14px"
    >
      {{ text }}
    </c-text>
  </c-flex>
</template>

<script>
import { CFlex, CText } from '@chakra-ui/vue'

export default {
  name: 'Info2',
  components: { CText, CFlex },
  props: {
    variant: {
      type: String,
      default: 'success',
    },
    text: {
      type: String,
      default: 'text',
    },
  },
  computed: {
    style() {
      switch (this.variant) {
        case 'warning':
          return {
            color: 'warning.400',
            softColor: 'warning.50',
            colorHex: '#DA6D06',
          }
        case 'danger':
          return {
            color: 'danger.400',
            softColor: 'danger.50',
            colorHex: '#D32737',
          }
        case 'info':
          return {
            color: 'info.400',
            softColor: 'info.50',
            colorHex: '#0C72E0',
          }
        default:
          return {
            color: 'primary.400',
            softColor: 'primary.50',
            colorHex: '#008C81',
          }
      }
    },
  },
}
</script>
