var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "600",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.mealTime) + " - " + _vm._s(_vm.caloriesAmount) + "kkal ")]), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-clock.svg'),
      "height": "20px",
      "width": "20px",
      "fill": "#646161"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": "neutral.gray"
    }
  }, [_vm._v(" " + _vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime) + " ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "border-color": "neutral.gray"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }