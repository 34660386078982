var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(6, 1fr)'],
      "gap": ['6px'],
      "margin-bottom": "24px"
    }
  }, _vm._l(_vm.steps, function (step) {
    var _vm$constants$labelCo, _vm$constants$stepCol, _vm$constants$labelCo2, _vm$constants$stepCol2;

    return _c('c-box', {
      key: step.value,
      attrs: {
        "border-radius": "8px",
        "_hover": {
          backgroundColor: '#f2f2f2'
        },
        "cursor": step !== null && step !== void 0 && step.onClick ? 'pointer' : 'default'
      },
      on: {
        "click": function click($event) {
          step === null || step === void 0 ? void 0 : step.onClick();
        }
      }
    }, [step !== null && step !== void 0 && step.tooltip ? _c('c-tooltip', {
      attrs: {
        "label": step === null || step === void 0 ? void 0 : step.tooltip,
        "placement": "bottom"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "justify-content": "space-between"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "500",
        "color": ((_vm$constants$labelCo = _vm.constants.labelColor) === null || _vm$constants$labelCo === void 0 ? void 0 : _vm$constants$labelCo[step.color]) || _vm.constants.labelColor.neutral,
        "text-align": "center",
        "margin-bottom": "12px"
      }
    }, [_vm._v(" " + _vm._s(step.label) + " ")]), _c('c-box', {
      attrs: {
        "width": "100%",
        "height": "8px",
        "border-radius": "20px",
        "background-color": ((_vm$constants$stepCol = _vm.constants.stepColor) === null || _vm$constants$stepCol === void 0 ? void 0 : _vm$constants$stepCol[step.color]) || _vm.constants.stepColor.neutral
      }
    })], 1)], 1) : _c('c-flex', {
      attrs: {
        "flex-direction": "column",
        "justify-content": "space-between"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "500",
        "color": ((_vm$constants$labelCo2 = _vm.constants.labelColor) === null || _vm$constants$labelCo2 === void 0 ? void 0 : _vm$constants$labelCo2[step.color]) || _vm.constants.labelColor.neutral,
        "text-align": "center",
        "margin-bottom": "12px"
      }
    }, [_vm._v(" " + _vm._s(step.label) + " ")]), _c('c-box', {
      attrs: {
        "width": "100%",
        "height": "8px",
        "border-radius": "20px",
        "background-color": ((_vm$constants$stepCol2 = _vm.constants.stepColor) === null || _vm$constants$stepCol2 === void 0 ? void 0 : _vm$constants$stepCol2[step.color]) || _vm.constants.stepColor.neutral
      }
    })], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }