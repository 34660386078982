<template>
  <c-flex
    border-radius="8px"
    flex-direction="column"
    padding="16px"
    justify-content="center"
    align-items="center"
    gap="8px"
  >
    <c-image
      :src="require('@/assets/images/image-no-data.svg')"
      height="auto"
      width="100px"
      alt="no data"
    />
    <c-text
      font-size="16px"
      font-weight="600"
      color="neutral.lightGray"
      text-align="center"
    >
      {{ text }}
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: 'NoData2',
  props: {
    text: {
      type: String,
      default: 'No Data',
    },
  },
}
</script>
