<template>
  <c-box>
    <c-flex
      justify-content="space-between"
      align-items="center"
    >
      <c-text
        font-size="20px"
        font-weight="600"
        color="primary.400"
      >
        {{ mealTime }} - {{ caloriesAmount }}kkal
      </c-text>
      <c-flex
        justify-content="center"
        align-items="center"
        gap="8px"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-clock.svg')"
          height="20px"
          width="20px"
          fill="#646161"
        />
        <c-text
          font-size="16px"
          color="neutral.gray"
        >
          {{ startTime }} - {{ endTime }}
        </c-text>
      </c-flex>
    </c-flex>
    <c-divider
      border-color="neutral.gray"
    />
  </c-box>
</template>

<script>
import { CBox, CDivider, CFlex, CText } from '@chakra-ui/vue'

export default {
  name: 'MealTimeHeader',
  components: { CDivider, CFlex, CText, CBox },
  props: {
    mealTime: {
      type: [String, Number],
      default: '-', // Sarapan
    },
    caloriesAmount: {
      type: [String, Number],
      default: '-', // 200
    },
    startTime: {
      type: [String, Number],
      default: '-', // 06:00
    },
    endTime: {
      type: [String, Number],
      default: '-', // 07:00
    },
  },
}
</script>
